import React from 'react';
import './Homepage.css';
import { Link } from 'react-router-dom';
import profilePic from '../images/profile.jpeg'; // CV fotoğrafı

const Homepage = () => {
  return (
    <div className="homepage-container">
      <section className="intro-section">
        <div className="intro-photo">
          <img src={profilePic} alt="Coşku Mıhçı's CV" />
        </div>
        <div className="intro-text">
          <h1>Coşku Mıhçı</h1>
          <p>
            Welcome to my personal page! I am an AI researcher based in Istanbul, Turkey.
            Here you can find details about my academic work, projects, and publications.
            Born and raised in this vibrant city, I am inspired by its rich culture and history.
          </p>
        </div>
      </section>
{/* Research Explanation Section */}
<section className="research-section">
        <h2>Brief Explanation of My Research</h2>
        <p>
          My research primarily focuses on artificial intelligence, deep learning, and its applications
          in healthcare, natural language processing, and quantum computing. I am particularly interested
          in exploring how AI can be used to solve complex real-world problems, improve diagnostic tools,
          and develop efficient algorithms for data analysis.
        </p>
      </section>

      <section className="highlights-section">
        <h2>Featured Work</h2>
        <div className="highlight-item">
          <h3>Deep Learning for NLP</h3>
          <p>An extensive research on how deep learning is transforming natural language processing.</p>
          <Link to="/publications">Read more</Link>
        </div>
        <div className="highlight-item">
          <h3>Quantum Computing and Cryptography</h3>
          <p>Exploring the frontier of quantum computing in modern cryptography and its challenges.</p>
          <Link to="/research">Read more</Link>
        </div>
        <div className="highlight-item">
          <h3>AI in Healthcare</h3>
          <p>Analyzing the impact of AI in diagnostic tools and medical imaging technology.</p>
          <Link to="/publications">Read more</Link>
        </div>
      </section>

      <section className="links-section">
        <h2>Quick Links</h2>
        <ul>
          <li><Link to="/cv">View CV</Link></li>
          <li><Link to="/research">Research Projects</Link></li>
          <li><Link to="/publications">Publications</Link></li>
          <li><Link to="/teaching">Teaching Experience</Link></li>
        </ul>
      </section>

    
    </div>
  );
};

export default Homepage;
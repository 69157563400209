import React from 'react';
import './CV.css'; // İsteğe bağlı CSS dosyasını dahil edebiliriz

const CV = () => {
  return (
    <div className="cv-container">
      <h2>My CV</h2>
      <div className="cv-pdf">
        <embed src="path-to-your-cv.pdf" type="application/pdf" width="100%" height="800px" />
      </div>
    </div>
  );
};

export default CV;
import React from 'react';
import './Footer.css'; // CSS dosyasını import et
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import markaeviLogo from '../images/logo.png'; 


const Footer = () => {
  return (
    <footer>
      <div className="footer-left">
        <p>© 2024 Coşku Mıhçı</p>
      </div>
      <div className="footer-right">
        <a href="https://linkedin.com/in/coskumihci" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://github.com/coskumihci" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <p>Email: coskumihci@example.com</p>
        <p>Telefon: +123 456 7890</p>
      </div>
     

     

      {/* Created by Markaevi Bölümü */}
      <div className="created-by">
        <a href="https://www.markaevi.com.tr" target="_blank" rel="noopener noreferrer">
          <img src={markaeviLogo} alt="Markaevi Logo" className="markaevi-logo" />
        </a>
        <p>Created by <a href="https://www.markaevi.com.tr" target="_blank" rel="noopener noreferrer">Markaevi</a></p>
      </div>
    </footer>
  
  );
};

export default Footer;
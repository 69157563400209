import React from 'react';
import './Publications.css'; // Stil dosyasını import edelim

const publicationsData = [
  {
    title: 'Deep Learning for NLP',
    journal: 'Journal of Artificial Intelligence',
    year: '2023',
    link: 'https://doi.org/10.1000/xyz123',
  },
  {
    title: 'Quantum Computing and Cryptography',
    journal: 'International Journal of Quantum Information',
    year: '2022',
    link: 'https://doi.org/10.1000/abc456',
  },
  {
    title: 'AI in Healthcare: Trends and Challenges',
    journal: 'HealthTech Conference',
    year: '2021',
    link: 'https://doi.org/10.1000/def789',
  },
];

const Publications = () => {
  return (
    <div className="publications-container">
      <h2>Publications</h2>
      <ul className="publications-list">
        {publicationsData.map((pub, index) => (
          <li key={index} className="publications-item">
            <h3>{pub.title}</h3>
            <p><strong>Journal/Conference:</strong> {pub.journal}</p>
            <p><strong>Year:</strong> {pub.year}</p>
            {pub.link && <a href={pub.link} target="_blank" rel="noopener noreferrer">Read more</a>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Publications;
import React from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <div className="header-left">
        <h1><Link to="/">Coşku Mıhçı</Link></h1>
      </div>
      <nav className="header-menu">
        <ul>
          <li><Link to="/cv">CV</Link></li>
          <li><Link to="/research">Research</Link></li>
          <li><Link to="/teaching">Teaching Experience</Link></li>
          <li><Link to="/publications">Publications</Link></li> {/* Publications menü öğesi eklendi */}
        </ul>
      </nav>
      <div className="header-right">
        <a href="https://linkedin.com/in/coskumihci" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://github.com/coskumihci" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
      </div>
    </header>
  );
};

export default Header;
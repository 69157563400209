import React from 'react';
import './TeachingExperience.css'; // Stil dosyasını ekleyelim

const teachingData = [
  {
    institution: 'Harvard University',
    position: 'Assistant Professor',
    course: 'Introduction to Artificial Intelligence',
    term: 'Fall 2022',
  },
  {
    institution: 'MIT',
    position: 'Lecturer',
    course: 'Machine Learning 101',
    term: 'Spring 2021',
  },
  {
    institution: 'Stanford University',
    position: 'Visiting Professor',
    course: 'Deep Learning and Neural Networks',
    term: 'Fall 2020',
  },
];

const TeachingExperience = () => {
  return (
    <div className="teaching-container">
      <h2>Teaching Experience</h2>
      <ul className="teaching-list">
        {teachingData.map((experience, index) => (
          <li key={index} className="teaching-item">
            <h3>{experience.institution}</h3>
            <p><strong>Position:</strong> {experience.position}</p>
            <p><strong>Course:</strong> {experience.course}</p>
            <p><strong>Term:</strong> {experience.term}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TeachingExperience;
import React from 'react';
import './Research.css'; // Stil dosyasını import edelim

const researchData = [
  {
    title: 'Deep Learning Advances in Natural Language Processing',
    date: '2023',
    description: 'This research explores the latest advances in deep learning techniques applied to natural language processing, particularly in the context of conversational AI.',
    link: 'https://example.com/deep-learning-nlp',
  },
  {
    title: 'Quantum Computing: A New Frontier',
    date: '2021',
    description: 'This study focuses on the theoretical and practical implications of quantum computing in modern cryptography and machine learning.',
    link: 'https://example.com/quantum-computing',
  },
  {
    title: 'AI in Healthcare: Revolutionizing Diagnostics',
    date: '2020',
    description: 'An in-depth look at how artificial intelligence is being applied in the field of healthcare, with a focus on diagnostic tools and medical imaging.',
    link: 'https://example.com/ai-healthcare',
  },
];

const Research = () => {
  return (
    <div className="research-container">
      <h2>Research Projects</h2>
      <ul className="research-list">
        {researchData.map((research, index) => (
          <li key={index} className="research-item">
            <h3>{research.title}</h3>
            <p><strong>Date:</strong> {research.date}</p>
            <p>{research.description}</p>
            <a href={research.link} target="_blank" rel="noopener noreferrer">Read more</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Research;
import React from 'react';
import './Education.css'; // Stil dosyasını import edelim

const educationData = [
  {
    school: 'Harvard University',
    degree: 'Ph.D. in Computer Science',
    field: 'Artificial Intelligence',
    year: '2022',
  },
  {
    school: 'Massachusetts Institute of Technology (MIT)',
    degree: 'M.S. in Computer Science',
    field: 'Machine Learning',
    year: '2018',
  },
  {
    school: 'Stanford University',
    degree: 'B.S. in Computer Science',
    field: 'Software Engineering',
    year: '2016',
  },
];

const Education = () => {
  return (
    <div className="education-container">
      <h2>Education</h2>
      <ul className="education-list">
        {educationData.map((edu, index) => (
          <li key={index} className="education-item">
            <h3>{edu.school}</h3>
            <p><strong>Degree:</strong> {edu.degree}</p>
            <p><strong>Field of Study:</strong> {edu.field}</p>
            <p><strong>Graduation Year:</strong> {edu.year}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Education;
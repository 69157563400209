import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Homepage from './components/Homepage'; // Homepage bileşenini ekledik
import CV from './components/CV'; 
import Education from './components/Education';
import TeachingExperience from './components/TeachingExperience';
import Publications from './components/Publications';
import Research from './components/Research';
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Homepage />} /> {/* Ana sayfa olarak Homepage ayarlandı */}
            <Route path="/cv" element={<CV />} />
            <Route path="/education" element={<Education />} />
            <Route path="/teaching" element={<TeachingExperience />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/research" element={<Research />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;